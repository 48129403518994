



























































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { StockLogItem, Supply } from "../resources/interfaces";

@Component
export default class SupplySelect extends Vue {
  @Prop({ default: false })
  disabled!: boolean;

  @Prop()
  value!: StockLogItem;

  @Prop()
  supplies!: Supply[];

  open = false;

  showAddSupplyDialog = false;
  newSupply: {
    providerName?: string;
    supplyPrice?: number;
    supplyLink?: string;
  } = {};

  get displayValue() {
    let value = this.value.providerName;
    if (this.value.supplyName) {
      value += ` ${this.value.supplyName}`;
    }
    if (this.value.supplySpec) {
      value += ` ${this.value.supplySpec}`;
    }
    return value;
  }

  toggleOpen() {
    this.open = !this.open;
  }

  selectSupply(supply: Supply) {
    const setItem: Partial<StockLogItem> = {};
    setItem.providerName = supply.providerName;
    if (supply.price) {
      setItem.price = supply.price / (supply.unitSpec || 1);
      if (this.value.supplyQuantity) {
        setItem.quantity = this.value.supplyQuantity * (supply.unitSpec || 1);
      }
    }
    if (supply.productName || (supply.unit && supply.unitSpec)) {
      setItem.supplyId = supply._id;
      setItem.supplyName = supply.productName;
      setItem.supplyUnit = supply.unit;
      setItem.supplyPrice = supply.price;
      setItem.supplyUnitSpec = supply.unitSpec;
    } else {
      setItem.supplyName = undefined;
      setItem.supplyUnit = undefined;
      setItem.supplyPrice = undefined;
      setItem.supplyUnitSpec = undefined;
    }
    this.$emit("input", { ...this.value, ...setItem });
    this.open = false;
  }

  showAddSupply() {
    this.showAddSupplyDialog = true;
    this.open = false;
  }

  setTempSupply() {
    const setItem: Partial<StockLogItem> = {};
    setItem.supplyName = undefined;
    setItem.supplyUnit = undefined;
    setItem.supplyPrice = undefined;
    setItem.supplyUnitSpec = undefined;
    setItem.providerName = this.newSupply.providerName;
    setItem.price = this.newSupply.supplyPrice;
    setItem.supplyLink = this.newSupply.supplyLink;
    this.$emit("input", { ...this.value, ...setItem });
    this.showAddSupplyDialog = false;
  }
}
