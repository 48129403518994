





















































































































































































































































































































































































































































































































































































































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import { confirm } from "@/helpers/sweetAlert";
import {
  Product,
  FormulaItem,
  Supply,
  Scene,
  CardType,
  CardTypeReward,
  SetItem
} from "@/resources/interfaces";
import { ProductResource, CardTypeResource } from "@/resources";
import { StoreSelect, Poster } from "@/components";
import castEmbedded from "@/helpers/castEmbedded";
import FlavorGroups from "@/components/FlavorGroups.vue";
import ProductSearch from "@/components/ProductSearch.vue";
import CopyId from "@/components/CopyId.vue";

@Component({
  components: { StoreSelect, FlavorGroups, Poster, ProductSearch, CopyId }
})
export default class ProductDetail extends Vue {
  product: Partial<Product> = {
    type: "",
    categories: [],
    supplies: [],
    flavorGroups: [],
    formula: [],
    setItems: []
  };

  productSearchResult: Promise<Product[]> | Product[] = [];
  expandedSupplyIndexes: number[] = [];
  showFormula = false;
  showRawFormula = false;
  editingSetItems = false;
  rewardCardTypes: CardType[] = [];

  get editable() {
    return !this.product.id || !this.$user.store;
  }

  get valid() {
    if (
      this.product.type !== "food" &&
      !this.product.isSelfMade &&
      (!this.product.supplies?.length ||
        !this.product.supplies.every(
          s => s.store !== this.$user.store?.id || s.providerName
        ) ||
        !this.product.unit)
    ) {
      return false;
    }
    return this.product.name && this.product.type;
  }

  get totalCost() {
    return this.product.formula?.reduce(
      (cost, item) => {
        if (!item.product) return cost;
        if (item.flavorName) return cost;
        // @ts-ignore
        const itemCost = this.getItemCost(item);
        cost.low += itemCost.low;
        cost.high += itemCost.high;
        return cost;
      },
      { low: 0, high: 0 }
    );
  }

  supplyEditable(supply: { store: string; productName?: string }) {
    if (
      supply.productName &&
      !(this.$user.can("PRODUCT") && this.$user.can("BOOKING_ALL_STORE"))
    )
      return false;
    return !this.$user.store || this.$user.store.id === supply.store;
  }

  getItemCost(item: FormulaItem) {
    let supplyCosts = (item.product.supplies || [])
      .filter(s => !s.disabled && s.unitSpec)
      .map(supply => {
        if (supply.price === undefined) return NaN;
        return (
          supply.price /
          (1 + (supply.taxRateInclusive || 0)) /
          (supply.unitSpec || 1)
        );
      })
      .filter(p => !isNaN(p))
      .sort((a, b) => (a - b > 0 ? 1 : -1));
    if (item.product.isSelfMade) {
      supplyCosts = [item.product.buyPrice || NaN];
    }
    const lowCost = supplyCosts[0] as number;
    const highCost = supplyCosts[supplyCosts.length - 1] as number;
    if (item.product.unitSpec === undefined)
      return {
        low: NaN,
        high: NaN
      };
    return {
      low: (lowCost / item.product.unitSpec) * item.quantity,
      high: (highCost / item.product.unitSpec) * item.quantity
    };
  }

  goBooking() {
    const type = this.product.type === "lab" ? "event" : this.product.type;
    this.$router.push(`/booking/${type}?product=${this.product.id}`);
  }

  goStock() {
    this.$router.push(`/stock/index?product=${this.product.id}&nonEmpty=false`);
  }

  goStockLog() {
    this.$router.push(`/stock-log/index?product=${this.product.id}`);
  }

  goFormulaProduct() {
    this.$router.push(`/product/index?formulaProduct=${this.product.id}`);
  }

  isSupplyExpanded(index: number) {
    return this.expandedSupplyIndexes.includes(index);
  }

  toggleExpandSupply(index: number) {
    if (this.isSupplyExpanded(index)) {
      this.expandedSupplyIndexes = this.expandedSupplyIndexes.filter(
        i => i !== index
      );
    } else {
      this.expandedSupplyIndexes.push(index);
    }
  }

  async save() {
    this.product = await ProductResource.save({
      ...this.product,
      supplies: (this.product.supplies || []).map(s =>
        castEmbedded(s, ["store"])
      ),
      formula: (this.product.formula || []).map(s =>
        castEmbedded(s, ["product"])
      ),
      rawFormula: undefined
    });

    this.editingSetItems = false;

    this.$notify({
      message: "保存成功",
      icon: "check",
      type: "success"
    });

    if (this.$route.params.id === "add") {
      this.$router.replace(`/product/${this.product.id}`);
    }
  }
  async remove() {
    if (
      !(await confirm(
        "确定要删除这个产品",
        `这个操作不可撤销`,
        "确定删除",
        "error"
      ))
    )
      return;
    await ProductResource.delete({ id: this.product.id });
    this.$router.back();
  }

  addSupply() {
    if (!this.product.supplies) {
      this.product.supplies = [];
    }
    if (this.$user.store) {
      // @ts-ignore
      this.product.supplies.push({ store: this.$user.store.id });
    } else {
      this.product.supplies.push({});
    }
    this.$set(this.product, "supplies", this.product.supplies);
  }
  updateSupply(index: number, field: keyof Supply, value: any) {
    if (!this.product.supplies) return;
    this.product.supplies[index][field] = value;
    this.product.supplies = [...this.product.supplies];
  }
  removeSupply(index: number) {
    if (this.isSupplyExpanded(index)) {
      this.toggleExpandSupply(index);
    }
    if (!this.product.supplies) {
      return;
    }
    this.product.supplies = this.product.supplies
      .slice(0, index)
      .concat(this.product.supplies.slice(index + 1));
  }

  addFormula() {
    if (!this.product.formula) {
      this.product.formula = [];
    }
    if (this.product.formula.length === 0 && !this.showFormula) {
      this.showFormula = true;
    }
    this.product.formula.push({});
    this.$set(this.product, "formula", this.product.formula);
  }
  async onSearchProduct(v: string, type: string) {
    if (!v) {
      this.productSearchResult = Promise.resolve([]);
      return;
    }
    this.productSearchResult = ProductResource.query({
      name: v,
      type
    });
  }
  selectFormulaProduct(item: FormulaItem, product: Product) {
    item.product = product;
    this.productSearchResult = [];
  }
  removeFormula(index: number) {
    if (!this.product.formula) {
      return;
    }
    this.product.formula = this.product.formula
      .slice(0, index)
      .concat(this.product.formula.slice(index + 1));
    if (this.product.formula.length === 0 && this.showFormula) {
      this.showFormula = false;
    }
  }

  addSetItem() {
    if (!this.product.setItems) {
      this.product.setItems = [];
    }
    this.product.setItems.push({
      name: "",
      quantity: 1,
      isFixedProducts: false,
      products: []
    });
    this.$set(this.product, "setItems", this.product.setItems);
  }
  removeSetItem(index: number) {
    if (!this.product.setItems) {
      return;
    }
    this.product.setItems = this.product.setItems
      .slice(0, index)
      .concat(this.product.setItems.slice(index + 1));
  }
  addSetItemProduct(item: SetItem, product: Product) {
    // if (item.products?.some(p => p.id === product.id)) return;
    item.products.push({
      id: product.id,
      name: product.name,
      no: product.no
    });
    this.$set(this.product, "setItems", this.product.setItems);
  }
  removeSetItemProduct(item: SetItem, product: Product) {
    item.products = item.products.filter(p => p.id !== product.id);
  }

  addReward() {
    if (!this.product.rewards) {
      this.product.rewards = [];
    }
    this.product.rewards.push({
      rewardAt: "checkIn",
      rewardEveryTime: false
    });
    this.$set(this.product, "rewards", this.product.rewards);
  }
  removeReward(index: number) {
    if (!this.product.rewards) {
      return;
    }
    this.product.rewards = this.product.rewards
      .slice(0, index)
      .concat(this.product.rewards.slice(index + 1));
  }
  async searchRewardCardTypes(v: string) {
    if (typeof v !== "string") return;
    this.rewardCardTypes = await CardTypeResource.query({
      type: "coupon",
      scene: Scene.FOOD,
      enabled: true,
      title: v
    });
  }
  selectRewardCardType(reward: CardTypeReward, item: CardType) {
    reward.slug = item.slug;
    reward.title = item.title;
    this.rewardCardTypes = [];
  }

  async created() {
    if (this.$route.params.id !== "add") {
      this.product = await ProductResource.get({ id: this.$route.params.id });
    } else {
      if (this.$route.query.type) {
        this.product.type = this.$route.query.type as string;
      }
      if (this.$route.query.category) {
        this.product.categories = [this.$route.query.category as string];
      }
      this.addSupply();
    }
  }
}
