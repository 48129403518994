






















import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { Product } from "../resources/interfaces";
import { ProductResource } from "@/resources";

@Component
export default class ProductSearch extends Vue {
  @Prop({ default: "搜索餐品" })
  label!: string;

  @Prop({ default: "food" })
  type!: string;

  products: Product[] = [];

  open = false;

  searchTerm = "";

  @Watch("searchTerm") async onSearch() {
    if (!this.searchTerm) {
      this.products = [];
      return;
    }
    this.products = await ProductResource.query({
      type: this.type,
      name: this.searchTerm
    });
  }

  toggleOpen() {
    this.open = !this.open;
  }

  selectProduct(product: Product) {
    this.searchTerm = "";
    this.open = false;
    this.$emit("select", product);
  }
}
