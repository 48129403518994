

























































































































































import List from "@/components/List";
import { StockResource } from "@/resources";
import { Stock } from "@/resources/interfaces";
import Component from "vue-class-component";

@Component
export default class StockList extends List<Stock> {
  name = "stock";
  resource = StockResource;
  currentSort = "lastVerifiedAt";
  currentSortOrder = "desc";
  inventory = false;
  categories: string[] = [];
  searchQuery: Record<string, any> = {
    store: "",
    dept: "",
    usage: "",
    nonEmpty: true,
    productCategory: ""
  };
  saveSearchQueryKeys = ["store", "dept", "usage", "productCategory"];
  get inventoriable() {
    return (
      this.searchQuery.dept &&
      this.searchQuery.usage &&
      (this.$user.store || this.searchQuery.store) &&
      this.searchQuery.nonEmpty
    );
  }
  async saveInventory() {
    this.inventory = false;

    const set = this.queriedData
      // @ts-ignore
      .filter(i => i.quantityInventory !== undefined)
      .map(i => ({
        id: i.id,
        // @ts-ignore
        quantity: i.quantityInventory
      }));
    await StockResource.update(
      {
        dept: this.searchQuery.dept,
        usage: this.searchQuery.usage,
        store: this.searchQuery.store
      },
      // @ts-ignore
      set
    );
    await this.queryData();
  }
  goLogs(item: Stock) {
    let url = "/stock-log?product=" + item.product.id;
    url += "&store=" + item.store.id;
    url += "&dept=" + item.dept;
    url += "&usage=" + item.usage;
    this.$router.push(url);
  }
  async aggregateStocks() {
    await StockResource.create(
      {},
      {
        aggregateStocks: true,
        store: this.searchQuery.store,
        dept: this.searchQuery.dept,
        usage: this.searchQuery.usage
      }
    );
    this.queryData();
    this.$notify({
      message: "重新计算库存完成",
      icon: "add_alert",
      type: "success"
    });
  }
  download() {
    const params: Record<string, any> = {
      token: window.localStorage.token,
      ...this.query
    };
    const queryString = Object.keys(params)
      .filter(key => params[key] !== undefined)
      .map(key => `${key}=${params[key]}`)
      .join("&");
    window.location.href =
      process.env.VUE_APP_API_BASE + "/stock-sheet?" + queryString;
  }
  async loadCategories() {
    this.categories = (
      await StockResource.query({
        ...this.searchQuery,
        aggregateCategories: true
      })
    ).filter(c => c) as unknown as string[];
    this.searchQuery.productCategory = undefined;
  }
  async created() {
    // this.searchQuery = {
    //   ...this.searchQuery,
    //   nonEmpty: this.$route.query.nonEmpty !== "false",
    //   product: this.$route.query.product
    // };
    this.searchQuery.nonEmpty = this.$route.query.nonEmpty !== "false";
    this.searchQuery.product = this.$route.query.product;
    this.loadCategories();
  }
}
