























































































































































































































































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import {
  Product,
  StockLog,
  Provider,
  Stock,
  StockLogStatus,
  File,
  Supply,
  Receive
} from "@/resources/interfaces";
import {
  ProductResource,
  StockLogResource,
  ProviderResource,
  StockResource
} from "@/resources";
import { StoreSelect, FilesCard, PaymentsCard, Poster } from "@/components";
import { Watch, Prop } from "vue-property-decorator";
import castEmbedded from "@/helpers/castEmbedded";
import { confirm, alert } from "@/helpers/sweetAlert";
import { Route, NavigationGuardNext } from "vue-router";
import StockLogItemLine from "@/components/StockLogItemLine.vue";
import moment from "moment";
import CopyId from "@/components/CopyId.vue";

@Component({
  components: {
    StoreSelect,
    FilesCard,
    PaymentsCard,
    StockLogItemLine,
    Poster,
    CopyId
  }
})
export default class StockLogDetail extends Vue {
  stockLog: Partial<StockLog> = {
    items: [],
    reason: window.localStorage.getItem("stockLogDefaultReason") || "采购"
  };

  products: Product[] | Promise<Product[]> = [];
  stocks: Partial<Stock>[] | Promise<Partial<Stock>[]> = [];
  providers: Provider[] = [];
  searchTimeout?: number;
  productSearchTerm = "";
  providerSearchTerm = "";
  selectedProviderName = "";
  checkedItemsId: string[] = [];
  showPaymentDialog = false;
  paymentSubmitting = false;
  paymentAmount: number = NaN;
  paymentDate: string = moment().format("YYYY-MM-DD");
  paymentGateway: string = "alipay";
  forceApproval = false;
  submitting = false;

  showReceiveDialog: Receive | null = null;

  showDateModify = false;
  set = {
    createdAt: "",
    inStockAt: ""
  };

  @Prop({ default: false })
  add!: boolean;

  get availableUsages() {
    let usages = this.$config.stockUsages || [];
    if (this.stockLog.reason === "报废") {
      usages = usages.filter(
        usage => !usage.match(/服务|招待|工程|房租|物业|人事/)
      );
    }
    return usages;
  }

  get valid() {
    if (!this.stockLog.items?.length) return false;
    if (this.errorMessage) return false;
    if (!this.stockLog.store && !this.stockLog.dept) return false;
    if (this.stockLog.items.some(i => !i.product && !i.desc)) return false;
    return true;
  }

  get errorMessage() {
    if (!this.stockLog.items?.length) return "";
    if (
      this.stockLog.items.some(
        i =>
          (this.providers.find(p =>
            [p.name, p.officialName].includes(i.providerName || "")
          )?.payOn || "order") === "order"
      ) &&
      this.hasPrePostPayProvider
    ) {
      return "一个订单不能同时包含现结供应商和非现结供应商";
    }
    if (
      this.stockLog.reason !== "耗用" &&
      this.stockLog.items.some(i => (i.quantity || 0) > 0) &&
      this.stockLog.items.some(i => (i.quantity || 0) < 0)
    ) {
      return "一个订单不能同时包含入库和出库";
    }
    if (
      this.stockLog.items.some(
        i => i.supplyName !== "库存" && i.quantity && i.quantity < 0
      ) &&
      ["生产", "报废"].includes(this.stockLog.reason as string)
    ) {
      return "生产/报废只能填写正数";
    }
    if (
      !this.stockLog.id &&
      this.stockLog.items.some(i => i.quantity && i.quantity > 0) &&
      this.stockLog.reason === "调拨"
    ) {
      return "创建调拨只能填写负数（调出），调入单会自动生成";
    }
    return "";
  }

  get editable() {
    if (this.$user.can("DEVELOP")) return true;
    if (this.$user.can("CASHIER") && this.$user.can("BOOKING_ALL_STORE")) {
      return true;
    }
    if (
      this.$user.can("CASHIER") &&
      this.stockLog.status === StockLogStatus.SENT
    ) {
      return true;
    }
    if (!this.stockLog.status || this.stockLog.status === StockLogStatus.NEW) {
      return true;
    }
    return false;
  }

  get checkable() {
    if (!this.stockLog.items?.length) return false;
    return this.receivable || this.payable || this.refundable;
  }

  get removable() {
    if (!this.stockLog.id) return false;
    if (!this.stockLog.payments?.length && this.$user.can("DEVELOP")) {
      return true;
    }
    if (
      this.stockLog.dept === "零售" &&
      this.$user.can("BOOKING_ALL_STORE") &&
      this.$user.can("GIFT_BOOKING") &&
      this.$user.can("CASHIER")
    ) {
      return true;
    }
    return (
      this.stockLog.status === StockLogStatus.NEW &&
      this.stockLog &&
      this.stockLog.createdBy?.id === this.$user.id
    );
  }

  get payable() {
    if (
      [StockLogStatus.NEW, StockLogStatus.REQUESTED].includes(
        this.stockLog.status as StockLogStatus
      )
    ) {
      return false;
    }
    if (["生产", "报废"].includes(this.stockLog.reason as string)) return false;
    if (this.hasPrePostPayProvider && this.stockLog.reason === "采购") {
      return false;
    }
    if (!this.$user.can("CASHIER")) return false;
    return true;
  }

  get receiveLabel() {
    if (this.stockLog.reason === "报废") return "处理";
    return "收货";
  }

  get receivable() {
    if (!this.stockLog.id) return false;
    if (!["采购", "调拨", "报废"].includes(this.stockLog.reason || ""))
      return false;
    if (
      !(
        this.$user.can("DEVELOP") ||
        this.$user.can("CASHIER") ||
        !this.stockLog.responsibleBy ||
        this.$user.id === this.stockLog.responsibleBy?.id
      )
    ) {
      return false;
    }
    return !["new", "requested"].includes(this.stockLog.status || "");
  }

  get refundable() {
    if (!["采购", "调拨"].includes(this.stockLog.reason || "")) return false;
    if (this.$user.can("CASHIER"))
      return !["new", "requested"].includes(this.stockLog.status || "");
    return false;
  }

  get stockable() {
    if (this.stockLog.items?.every(i => !i.product)) return false;
    if (this.stockLog.status === "new" && this.stockLog.reason === "生产") {
      return true;
    }
    if (this.stockLog.status === "sent" && this.stockLog.dept === "零售") {
      return true;
    }
    return (
      this.stockLog.status === StockLogStatus.RECEIVED &&
      this.$user.can("CASHIER")
    );
  }

  get dateModifiable() {
    return this.$user.can(
      "PRODUCT",
      "CASHIER",
      "PAYMENT_ALL_DATE",
      "BOOKING_ALL_STORE"
    );
  }

  get hasPrePostPayProvider() {
    return !!this.stockLog.items?.some(i => {
      const provider = this.providers.find(p =>
        [p.name, p.officialName].includes(i.providerName || "")
      );
      return provider?.payOn && provider.payOn !== "order";
    });
  }

  get approvalRequiredNotForced() {
    if (this.stockLog.reason === "报废") return true;
    if (this.stockLog.reason === "调拨") return false;
    return (
      !this.stockLog.items?.length ||
      this.stockLog.usage === "食材-客用" ||
      !this.hasPrePostPayProvider ||
      this.stockLog.reason === "预付/结款"
    );
  }

  get approvalRequired() {
    return this.forceApproval || this.approvalRequiredNotForced;
  }

  get total() {
    return (
      this.stockLog.items?.reduce(
        (total, item) => total + (item.quantity || 0) * (item.price || 0),
        0
      ) || 0
    );
  }

  @Watch("productSearchTerm")
  async searchProduct(v: string) {
    if (typeof v !== "string" || !v) {
      this.stocks = Promise.resolve([]);
      return;
    }
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }
    const query: Record<string, any> = {
      productName: v
    };
    query.store = this.stockLog.store?.id;
    if (this.stockLog.reason !== "调拨") {
      if (this.providerSearchTerm) {
        query.providerName = this.providerSearchTerm;
      }
    }
    if (this.stockLog.dept && !this.stockLog.fromDept) {
      query.dept = this.stockLog.dept;
    }
    if (this.stockLog.usage) {
      query.usage = this.stockLog.usage;
    }
    this.searchTimeout = window.setTimeout(async () => {
      this.stocks = StockResource.query(query);
      this.stocks.then(stocks => {
        if (!stocks.length) {
          console.log("no stock found, try products");
          ProductResource.query({
            ...query,
            name: query.productName,
            productName: undefined
          }).then(products => {
            console.log(
              "products:",
              products.map(p => p.name)
            );
            this.stocks = Promise.resolve(
              products.map(product => ({ product }))
            );
          });
        }
      });
    }, 200);
  }

  async selectProduct(stock: Stock) {
    this.productSearchTerm = "";
    const product = await ProductResource.get({ id: stock.product.id });
    let [firstSupply] = this.validSupplies(product).filter(
      s =>
        !this.providerSearchTerm || s.providerName === this.providerSearchTerm
    ) as [Supply | undefined];

    console.log("found supply", firstSupply);

    let providerName = firstSupply?.providerName;

    // if (this.providerSearchTerm) {
    //   providerName = this.providerSearchTerm;
    // }

    let price =
      firstSupply?.price && firstSupply.price / (firstSupply.unitSpec || 1);

    if (["调拨", "报废"].includes(this.stockLog.reason as string)) {
      firstSupply = undefined;
      price = stock.quantity ? stock.amount / stock.quantity : undefined;
      if (this.stockLog.fromDept) {
        providerName = this.stockLog.fromDept;
      } else if (this.stockLog.fromStore) {
        providerName = this.$stores.find(
          s => s.id === this.stockLog.fromStore
        )?.name;
      } else if (this.stockLog.reason === "报废") {
        providerName = this.stockLog.store?.name;
      }
    }

    if (!this.stockLog.items) return;

    this.stockLog.items.push({
      _id: Date.now().toFixed(),
      product,
      providerName,
      price,
      supplyId: firstSupply?._id,
      supplyName: firstSupply?.productName,
      supplyUnit: firstSupply?.unit,
      supplyUnitSpec: firstSupply?.unitSpec,
      supplySpec: firstSupply?.spec,
      supplyPrice: firstSupply?.productName ? firstSupply.price : undefined,
      supplyLink: firstSupply?.link
    });
  }

  @Watch("providerSearchTerm")
  async searchProvider(v: string) {
    if (typeof v !== "string" || !v) {
      this.stocks = Promise.resolve([]);
      return;
    }
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }
  }

  async selectProvider(provider: Provider) {
    this.providerSearchTerm = provider.name;
    this.stocks = StockResource.query({
      providerName: this.providerSearchTerm
    });
  }

  async onAutoCompleteEnter(e: KeyboardEvent) {
    const input = e.srcElement as HTMLInputElement;
    input.blur();
    await this.$nextTick();
    input.focus();
  }

  validSupplies(product?: Product) {
    if (!product?.supplies) return [];
    return product.supplies.filter(
      s => !s.disabled && (s.store === this.stockLog.store?.id || !s.store)
    );
  }

  removeItem(id: string) {
    this.stockLog.items = this.stockLog.items?.filter(i => i._id !== id);
  }

  addEmptyItem() {
    this.stockLog.items?.push({
      _id: Date.now().toFixed(),
      quantity: 1,
      providerName: this.providerSearchTerm || undefined
    });
  }

  checkItem(id: string) {
    if (this.checkedItemsId.includes(id)) {
      this.checkedItemsId = this.checkedItemsId.filter(i => i !== id);
    } else {
      this.checkedItemsId.push(id);
    }
  }

  checkAll() {
    if (this.checkedItemsId.length > 0) {
      this.checkedItemsId = [];
    } else {
      this.checkedItemsId =
        this.stockLog.items?.map(i => i._id as string) || [];
    }
  }

  async setStatus(status: StockLogStatus) {
    const set: Partial<StockLog> = {
      status,
      paymentDate: this.stockLog.paymentDate,
      receiveDate: this.stockLog.receiveDate
    };
    if (status === StockLogStatus.SENT) {
      if (this.stockLog.reason === "采购") {
        if (!(await confirm("请确认需求已发送给供应商", ""))) return;
      } else if (
        this.stockLog.reason === "调拨" &&
        this.stockLog.items?.some(i => (i.quantity || 0) < 0)
      ) {
        if (!(await confirm(`确认已发往对方`, "将同时创建对方的调入单"))) {
          return;
        }
      }
    }
    if (status === StockLogStatus.REQUESTED && this.approvalRequired) {
      if (
        !(await confirm(
          "确定提交审批？",
          "将自动创建企业微信审批，提交后无法修改申请内容"
        ))
      ) {
        return;
      }
    }
    if (status === StockLogStatus.NEW) {
      this.$notify({
        message: "请前往企业微信撤回该审批",
        icon: "warning",
        type: "warning"
      });
      return;
    }
    if (
      status === StockLogStatus.IN_STOCK &&
      !(await confirm(
        `确定一键${
          this.stockLog.items?.every(i => (i.quantity || 0) < 0) ? "出" : "入"
        }库？`,
        "此操作无法撤销"
      ))
    ) {
      return;
    }
    this.submitting = true;
    try {
      this.stockLog = await StockLogResource.update(
        { id: this.stockLog.id },
        set
      );
      this.$notify({
        message: "状态已更新",
        icon: "check",
        type: "success"
      });
    } catch (e) {
      //
    }
    this.submitting = false;
  }

  async createPayment() {
    if (isNaN(this.paymentAmount) || !this.paymentDate) return;

    this.paymentSubmitting = true;

    const paid = +(
      (this.stockLog.amountPaid || 0) + +this.paymentAmount
    ).toFixed(2);
    if (paid - this.total > 50 || paid / this.total > 1.1) {
      this.$notify({
        message: "付款总额不能超过计划金额",
        icon: "warning",
        type: "warning"
      });
      return;
    }

    this.stockLog = await StockLogResource.update(
      {
        id: this.stockLog.id,
        itemsPaid: this.checkedItemsId.join(),
        amountPaid: this.paymentAmount,
        paymentGateway: this.paymentGateway,
        paymentDate: this.paymentDate
      },
      { amountPaid: +this.paymentAmount + (this.stockLog.amountPaid || 0) }
    );
    this.checkedItemsId = [];
    this.paymentAmount = NaN;
    this.paymentDate = moment().format("YYYY-MM-DD");
    this.paymentGateway = "alipay";
    this.showPaymentDialog = false;
    this.paymentSubmitting = false;
  }

  openReceiveDialog(receive?: Receive) {
    if (!receive) {
      receive = {
        photoUrls: [],
        items: [...this.checkedItemsId]
      };
      this.stockLog.receives?.push(receive);
    }
    this.showReceiveDialog = receive;
  }

  closeReceiveDialog() {
    this.stockLog.receives = this.stockLog.receives?.filter(
      receive => receive._id
    );
    this.showReceiveDialog = null;
  }

  getReceiveItemsName(receive: Receive) {
    return receive.items
      .map(id => {
        const item = this.stockLog.items?.find(item => item._id === id);
        return item?.product?.name || item?.desc;
      })
      .filter(name => name)
      .join("、");
  }

  async receive() {
    if (!this.stockLog.receives) this.stockLog.receives = [];
    this.stockLog = await StockLogResource.update(
      { id: this.stockLog.id },
      { receives: this.stockLog.receives }
    );
    this.showReceiveDialog = null;
    this.checkedItemsId = [];
  }

  async refund() {
    if (
      !(await confirm(
        "确定退货且不重新购买？",
        "将会将本项数量置为0，退款需要另行录入",
        "退货",
        "warning"
      ))
    ) {
      return;
    }

    this.stockLog = await StockLogResource.update(
      {
        id: this.stockLog.id,
        itemsRefund: this.checkedItemsId.join()
      },
      {}
    );
    this.checkedItemsId = [];
  }

  async modifyDate() {
    const set: Partial<StockLog> = {};
    if (this.set.createdAt) {
      set.createdAt = moment(this.set.createdAt).endOf("day").toDate();
    }
    if (this.set.inStockAt) {
      set.inStockAt = moment(this.set.inStockAt).endOf("day").toDate();
    }
    this.stockLog = await StockLogResource.update(
      { id: this.stockLog.id },
      set
    );
    this.set = { createdAt: "", inStockAt: "" };
    this.showDateModify = false;
  }

  async save() {
    const itemsToFix = this.stockLog.items?.filter(
      i =>
        (i.product || i.desc) &&
        (i.quantity === undefined ||
          (["采购", "调拨"].includes(this.stockLog.reason as string) &&
            !i.providerName) ||
          (!["生产"].includes(this.stockLog.reason as string) && !i.price))
    );
    if (itemsToFix?.length) {
      const names = itemsToFix.map(i => i.product?.name || i.desc);
      await alert("以下项目信息未完善", names.join("、"), null, "error");
      return;
    }
    this.stockLog = await StockLogResource.save({
      ...this.stockLog,
      items: this.stockLog.items?.map(i => {
        i = castEmbedded(i, ["product"]);
        if (i._id?.length === 13) {
          delete i._id;
        }
        return i;
      })
    });
    if (this.stockLog.dept)
      window.localStorage.setItem("stockLogDefaultDept", this.stockLog.dept);
    if (this.stockLog.reason)
      window.localStorage.setItem(
        "stockLogDefaultReason",
        this.stockLog.reason
      );
    if (this.add) {
      const continuous = await confirm(
        `${this.stockLog.reason}已保存`,
        null,
        "继续创建",
        "success",
        "返回"
      );
      if (continuous) {
        delete this.stockLog.id;
        delete this.stockLog.createdAt;
        delete this.stockLog.status;
        this.stockLog.items = [];
      } else {
        // this.$router.back();
        this.$router.replace("/stock-log/" + this.stockLog.id);
      }
    } else {
      this.$notify({
        message: "保存成功",
        icon: "check",
        type: "success"
      });
    }
  }

  async remove() {
    if (
      !(await confirm(
        "确定删除这个采购调拨单吗？",
        "该操作不可撤销",
        "删除",
        "error"
      ))
    )
      return;
    await StockLogResource.delete({ id: this.stockLog.id });
    this.$router.back();
  }

  async updateFiles(files: File[]) {
    this.$set(this.stockLog, "files", files);
    if (!this.stockLog.id) return;
    this.stockLog = await StockLogResource.update(
      { id: this.stockLog.id },
      // @ts-ignore
      { files: this.stockLog.files.map(f => f.id) }
    );
  }

  async beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext) {
    if (!to.params.id) return next();
    const stockLog = (await StockLogResource.get({
      id: to.params.id
    })) as StockLog;
    next(vm => {
      const page = vm as StockLogDetail;
      page.stockLog = stockLog;
    });
  }

  async mounted() {
    if (this.add) {
      if (this.$user.store) {
        this.$set(this.stockLog, "store", this.$user.store);
      }
      if (this.$route.query.store) {
        this.$set(
          this.stockLog,
          "store",
          this.$config.stores?.find(s => s.id === this.$route.query.store)
        );
      }
      if (this.$route.query.reason) {
        this.$set(this.stockLog, "reason", this.$route.query.reason);
      }
      if (this.$route.query.dept) {
        this.$set(this.stockLog, "dept", this.$route.query.dept);
      }
      if (this.$route.query.usage) {
        this.$set(this.stockLog, "usage", this.$route.query.usage);
      }
    }
    this.providers = await ProviderResource.query({ limit: false });
  }
}
