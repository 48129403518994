



















































import List from "@/components/List";
import ProductCategoryDialog from "./ProductCategoryDialog.vue";
import { ProductCategoryResource } from "@/resources";
import { ProductCategory } from "@/resources/interfaces";
import Component from "vue-class-component";

@Component({ components: { ProductCategoryDialog } })
export default class ProductCategoryList extends List<ProductCategory> {
  name = "product-category";
  resource = ProductCategoryResource;
  showingDialog = false;
  currentItem: ProductCategory | null = null;
  showDialog(cat?: ProductCategory) {
    this.showingDialog = true;
    if (cat) {
      this.currentItem = cat;
    } else {
      this.currentItem = null;
    }
  }
  async itemUpdated() {
    this.queryData();
  }
  async created() {}
}
