




























import Vue from "vue";
import Component from "vue-class-component";
import { ProductCategory } from "@/resources/interfaces";
import { ProductCategoryResource } from "@/resources";
import { StoreSelect } from "@/components";
import { Watch, Prop } from "vue-property-decorator";

@Component({ components: { StoreSelect } })
export default class ProductCategoryDialog extends Vue {
  @Prop({ default: null })
  value!: ProductCategory | null;

  get category() {
    return this.value || ({ name: "" } as Partial<ProductCategory>);
  }

  set category(v) {
    this.$emit("input", v);
  }

  categories: ProductCategory[] | Promise<ProductCategory[]> = [];
  searchTimeout?: number;
  parentSearchTerm = "";

  @Watch("parentSearchTerm")
  async searchParent(v: string) {
    if (typeof v !== "string" || !v) {
      this.categories = Promise.resolve([]);
      return;
    }
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }
    this.searchTimeout = window.setTimeout(async () => {
      this.categories = ProductCategoryResource.query({ name: v });
    }, 200);
  }

  async selectParent(category: ProductCategory) {
    this.category.parent = category.id;
    this.parentSearchTerm = category.name;
  }

  async confirm() {
    await ProductCategoryResource.save(this.category);
    this.$emit("input", this.category);
    if (!this.category.id) {
      this.$emit("created", this.category);
    }
    this.$emit("cancel");
  }
}
